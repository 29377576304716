<template>
  <div class="first-time-dialog-content">
    <div class="first-time-dialog-content-body">
      <div v-for="n in 5" :key="n" class="explain-bloc">
        <img
          v-if="type === 'buy' && n < 6"
          :src="require(`@/assets/svg/v2/orders/buyFT${n}.svg`)"
          alt="Order first time"
        />
        <img
          v-if="type === 'sell' && n < 5"
          :src="require(`@/assets/svg/v2/orders/sellFT${n}.svg`)"
          alt="Order first time"
        />

        <div class="ft-text">
          <h3 v-if="type === 'buy' && n < 6">
            {{ $t(`product_page.orders.buy_ft_${n}_title`) }}
          </h3>
          <h3 v-if="type === 'sell' && n < 5">
            {{ $t(`product_page.orders.sell_ft_${n}_title`) }}
          </h3>

          <p v-if="type === 'buy' && n < 6">
            {{ $t(`product_page.orders.buy_ft_${n}_content`) }}
          </p>
          <p v-if="type === 'sell' && n < 5">
            {{ $t(`product_page.orders.sell_ft_${n}_content`) }}
          </p>
        </div>
      </div>
    </div>

    <div v-if="!readOnly" class="accept-bloc">
      <button class="generic-btn" @click="acceptConditions()">
        {{ $t('commons.continue') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  components: {},
  props: {
    type: {
      type: String,
      required: true,
      default: 'buy',
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    ...mapActions({
      agreeOrder: 'agreeOrder',
      getClient: 'getAccountDetails',
    }),
    async acceptConditions() {
      const payload = {
        type: this.type,
      }

      await this.agreeOrder(payload).then(() => {
        this.getClient()
        this.$emit('agree', this.type)
      }).catch((error) => {
        if (error.response.status === 406) {
          this.$router.push('/register');
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/root.scss';

.first-time-dialog-content {
  .first-time-dialog-content-body {
    .ft-text {
      display: flex;
      flex-direction: column;
    }
    .explain-bloc {
      display: flex;
      margin: 10px;

      img {
        margin-right: 10px;
        width: 30px;
        height: 30px;
      }

      div {
        h3 {
          color: white;
          font-size: 16px;
          font-weight: 600;
        }

        p {
          color: $subtitle;
          font-size: 15px;
          font-weight: 500;
        }
      }
    }
  }

  .input-transparent {
    -webkit-appearance: none;
    color: #ffffff;
    font-size: inherit;
    padding: 0 10px;
    outline: none;
    border: 1px solid rgba(255, 255, 255, 0.6);
    background-color: transparent;
    box-sizing: border-box;
    border-radius: 10px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.6);
    }

    &:focus {
      border-color: #ffffff;
    }
  }

  .accept-bloc {
    display: flex;
    justify-content: center;
    margin: 10px auto;
    width: 120px;
  }
}

.first-time-dialog-content-body::-webkit-scrollbar {
  width: 5px;
}

.first-time-dialog-content-body::-webkit-scrollbar-track {
  background: #333333;
}

.first-time-dialog-content-body::-webkit-scrollbar-thumb {
  background-color: #818181;
  border-radius: 10px;
}

.first-time-dialog-content-body::-webkit-scrollbar-thumb:hover {
  background-color: $base-color;
}

.buyer-title,
.seller-title {
  color: #84cc16;
  font-size: 24px;
  font-weight: 600;
}

.seller-title {
  color: #f43f5e;
}

.sellerArrow {
  background: #f43f5e !important;
}

.sellerBg {
  border-radius: 20px 20px 0px 0px !important;
  background: linear-gradient(
    180deg,
    rgba(244, 63, 94, 0.16) 0%,
    rgba(244, 63, 94, 0) 100%
  ) !important;
}

@media screen and (max-width: 500px) {
  .first-time-dialog-content-body {
    .explain-bloc {
      margin: 0!important;
      margin-bottom: 10px!important;

      div {

        h3 {
          font-weight: 500!important;
          font-size: 15px!important;
        }

        p {
          font-size: 13px!important;
          font-weight: 400!important;
        }
      }
    }
  }
}
</style>
